
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorPlayersCategoriesListFormCreate} from "@/models/app/administrator/players/categories/list/form/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElButton,
} from "element-plus";
import {Plus} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElButton,
    Plus,
  },
})
export default class AppAdministratorPlayersCategoriesListFormCreateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  formData: AppAdministratorPlayersCategoriesListFormCreate = new AppAdministratorPlayersCategoriesListFormCreate();
  formDataRules = {
    content: {
      name: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
      from: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
      to: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/administration/player-category/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getPlayerCategories');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AppAdministratorPlayersCategoriesListFormCreate();
  }
}
