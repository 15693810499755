
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorPlayersNavigationVue from "@/components/administrator/players/navigation.vue";
import AppAdministratorPlayersCategoriesListIndexVue from "@/views/app/administrator/players/categories/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorPlayersNavigationVue,
    AppAdministratorPlayersCategoriesListIndexVue,
  },
})
export default class AppAdministratorPlayersCategoriesIndexVue extends Vue {}
